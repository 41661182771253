@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,600;1,300&display=swap");

$dark: rgb(17, 18, 27);
$light: rgb(71, 71, 77);

$border-radius: 4px;

body {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}

#dynamic-reference {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 600px;
  height: 600px;
}

.controls {
  max-width: 100%;
  display:flex;
  flex-direction: column;
  justify-content: center;

  padding: 0 25px;
}

.input-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.h-spaced {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.subtitle {

  font-size: 12px;
  color: rgb(138, 138, 138);
}

label {
  color: $light;
  font-size: 14px;
  margin-bottom: 8px;
  margin-left: 5px
}

.bow-input {
  outline: none;
  color: $dark;
  border: 1px solid #e9ebed;
  font-size: 16px;
  border-radius: $border-radius;
  padding: 10px;
}

.page-content {
  display: flex;
  justify-content: center;
  //justify-content: space-between;
}

@media (max-width: 800px) {
  body,
  html {
    height: unset !important;
  }
  .page-content {
    flex-direction: column;
  }
  .inputs-outputs {
    margin-bottom: 15px;
    margin-right: 0 !important;
  }
  #known-variables {
    max-width: 50% !important;
  }
  #dynamic-reference {
    width: auto;
    height: auto;
  }
  .title {
    font-size: 36px !important;
    text-align: center;
  }
}

@media (max-width: 1200px) {
  .p5Canvas {
    width: 250px !important;
    height: 250px !important;
  }
  #dynamic-reference {
    align-items: center;
  }
}

.inputs-outputs {
  display: flex;
  flex-direction: row;
  margin-right: 50px;
  justify-content: center;
  width: 50%
}

.card {
  padding: 50px;
  background: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
  justify-content: center;
  text-align: center;
}

#known-variables {
  margin-bottom: 0px;
  max-width: 500px;
  text-align: left;
}

.inputs {
  grid-auto-flow: row;
  display: grid;
  gap: 10px;
}

#error {
  color: rgb(223, 173, 82);
  font-weight: 600;
  // text-transform: ;
}

.input-label {
  font-size: 18px;
  color: rgb(51, 64, 85);
  margin: 0 0 10px 0;
}

#output-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 50px;
  height: 100%;
  max-width: 200px;
  flex: 1;
}

.output-label {
  color: $light;
}

.output-value {
  text-align: right;
}

#outputs {
  display: flex;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  flex:1;
  gap: 10px;
  justify-content: center;
}

body {
  display: flex;
}

body,
html {
  height: 100%;
}

.title {
  max-width: 100%;
  font-size: 42px;
  margin-bottom: 25px;
  font-weight: 800;
  color: rgb(31, 37, 63);
  text-align: center;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textarea {
  width: 100%;
  text-align: center;
}

.row{
  display: flex;
  justify-content:center
}

.col{
  flex: 1;
  padding: 1em;
}

